@import '~normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';

@font-face {
  font-family: 'WorkSans-SemiBold';
  src: url(./assets/Fonts/WorkSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'WorkSans-Medium';
  src: url(./assets/Fonts/WorkSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'CircularTT-Medium';
  src: url(./assets/Fonts/CircularTT-Medium.ttf) format('truetype');
}

* {
  margin: 0%;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}
