@import "/src/assets/styles/mixin.scss";

.accountSetup {
  display: flex;
  height: 100vh;
  position: relative;
  background-color: $background-color-primary;

  .accountSetupSidebarCommon {
    position: fixed;
    top: 0;
    width: 18%;
    height: 100%;
    background: #585858 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    min-width: 250px;
  }

  // Left section styles
  .accountSetupLeftSideBar {
    left: 0;
    z-index: 1;

    .accountSetupBackButtonContainer {
      display: flex;
      justify-content: flex-end;
      padding: 10px;
      cursor: pointer;
      gap: 20px;

      .disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  // Main section styles
  .accountSetupMainSection {
    width: 64%;
    max-width: calc(100% - 500px);
    margin: 0 auto;
    overflow: auto;
    @include custom-scrollbar;

    .accountSetupMainSectionLoaderContainer {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  // Right section styles
  .accountSetupRightSideBar {
    right: 0;
    z-index: 1;

    .accountSetupFramewrkLogoContainer {
      width: 100%;
      margin: 36px 0;
      display: flex;
      justify-content: center;
      img {
        width: 159px;
        height: 62px;
      }
    }

    .setupProgressContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 80px;
      .setupProgressTitle {
        font-size: 18px;
        line-height: 21px;
        color: #ffffff;
        margin-bottom: 30px;
      }

      .setupProgressPercentageText {
        span {
          font-size: 18px;
          line-height: 21px;
          color: #ffffff;
        }
      }
    }

    .sectionDevider {
      border-top: 1px solid #2f2f2f;
      border-bottom: 1px solid #2f2f2f;
      opacity: 0.41;
      display: flex;
      justify-content: center;
      gap: 2px;
      padding: 3.5px;
      margin: 60px 12px 0 12px;
      div {
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background-color: black;
      }
    }

    .rightSideBarBottomContainer {
      height: 50vh;
      overflow: auto;
      @include custom-scrollbar;
    }
  }
}
