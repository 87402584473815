.accountSetupMenu {
  margin-top: 20px;

  .accountSetupMenuMainTitle {
    color: #ffffff;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }

  .accountSetupMenuContainer {
    margin: 24px;
    .accountSetupMenuItem {
      .accountSetupMenuDot {
        height: 22px;
        width: 22px;
        border-radius: 50%;
        background-color: #6a6a6a;
      }

      .accountSetupMenuTitle {
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        color: #ffffff;
        padding-top: 3px;
        opacity: 0.5;
      }

      &.active {
        .accountSetupMenuDot {
          background-color: #4eba9a;
          box-shadow: 0px 0px 6px #4eba9a;
        }

        .accountSetupMenuTitle {
          opacity: 1;
        }
      }
    }
  }
}
