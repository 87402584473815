@import "/src/assets/styles/mixin.scss";

.rightSideDetailComponent {
  min-height: 100vh;
  width: 50vw;
  background-color: $background-color-primary;
  display: flex;
  justify-content: center;
  // align-items: center;

  .signupContainer {
    min-width: 40%;
    margin: 20px;
    margin-top: 15vh;

    .signupInputContainer {
      margin-top: 24px;
      .signupInputTitle {
        color: #4a4a4a;
        font-size: 18px;
        margin-bottom: 8px;
      }

      .signupInput {
        svg {
          fill: $font-color-primary;
          margin-right: 10px;
        }
      }
    }

    .termAndConditionContainer {
      margin-top: 20px;

      .alreadyHaveAccountText {
        opacity: 0.38;
        font-size: 12px;
        margin-right: 10px;
      }

      .signInLinkButton {
        color: $font-color-primary;
        font-size: 13px;
        cursor: pointer;
        text-decoration: none;
      }

      .signInTermAndCondition {
        font-weight: 600;
        font-size: 13px;
        margin-left: 16px;
        line-height: 24px;
        margin-top: 4px;
      }
    }

    .startAccountSetupButton {
      margin-top: 10px;
      height: auto;

      // &:disabled {
      //   background-color: unset;
      // }

      .startAccountSetupButtonText {
        color: #4a4a4a;
        font-size: 20px;
        font-weight: 700;
        padding: 6px 10px;
      }
    }
  }
}
