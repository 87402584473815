.accountSetupIndexComponent {
  margin-top: 15px;
  margin-left: 28px;
  margin-right: 20px;
  color: #ffffff;
  .accountSetupIndexTitle {
    font-size: 18px;
    text-align: center;
    margin-bottom: 12px;
  }

  .accountSetupIndexDetail {
    .accountSetupIndexDetailTitle {
      margin-top: 5px;
      font-size: 14px;
    }
    .accountSetupIndexDetailText {
      margin-top: 9px;
      font-size: 12px;
      opacity: 0.62;
    }
  }
}
