@import "/src/assets/styles/mixin.scss";

.accountSetupIndexComponent {
  margin-top: 15px;
  margin-left: 28px;
  margin-right: 20px;
  color: #ffffff;
  .accountSetupIndexTitle {
    font-size: 18px;
    text-align: center;
    margin-bottom: 12px;
  }

  .accountSetupIndexDetail {
    .accountSetupIndexDetailTitle {
      margin-top: 5px;
      font-size: 14px;
    }
    .accountSetupIndexDetailText {
      margin-top: 9px;
      font-size: 12px;
      opacity: 0.62;
    }

    .inviteInputContainer {
      margin-top: 25px;

      .inviteInputTitle {
        font-size: 18px;
        color: #4eba9a;
        margin-bottom: 8px;
      }

      .signupInput {
        svg {
          fill: $font-color-primary;
          margin-right: 10px;
        }
      }

      .loginInput {
        svg {
          fill: $font-color-primary;
          margin-right: 10px;
        }
      }
    }

    .inviteButtonContainer {
      margin-top: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      
      .inviteButton {
        margin: auto;
        height: 50px;
        .accoutSetupNextSectionButtonText {
          color: #4a4a4a;
          font-size: 17px;
          font-weight: 700;
          padding: 0 50px;
        }
      }
    }
  }
}
