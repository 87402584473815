@import "/src/assets/styles/mixin.scss";

.leftSideDetailComponent {
  height: 100%;
  min-height: 100vh;
  width: 50vw;
  background-color: $background-color-primary;
  border-right: 1px solid #60707070;

  .leftSideDetailComponentContainer {
    margin: 50px;
    padding-left: 60px;

    .leftSideDetailAccountSetup {
      padding-right: 40px;

      .title {
        font-weight: 600;
        font-size: 30px;
        color: black;
        line-height: 30px;
        padding-left: 40px;
      }

      .titleSecondary {
        font-family: "Roboto", sans-serif;
        color: $font-color-secondary;
        padding-left: 40px;
      }

      .youtubeVideoContainer {
        margin-top: 20px;
        max-width: 600px;
        height: 300px;
        width: 100%;
        border-radius: 8px;
        iframe {
          border-radius: inherit;
          height: 100%;
          width: 100%;
        }
      }

      .accountSetupTitle {
        font-size: 16px;
      }

      .accountSetupDetail {
        color: $font-color-primary;
        font-size: 16px;
        margin-bottom: 8px;
      }
    }

    .leftSideDetailHowItWorksContainer {
      color: #2c2c2c;
      border-top: 1px solid #60707070;
      padding-top: 10px;
      padding-right: 40px;

      .howItWorksImageContainer {
        height: 38px;
        width: 38px;
        background-color: $font-color-primary;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        img {
          height: 25px;
          width: 25px;
          padding-right: 4px;
        }
      }

      .howItWorksTitle {
        color: $font-color-secondary;
        opacity: 0.31;
      }

      .howItWorksDetail {
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        opacity: 0.8;
        margin-top: 10px;
      }

      .howItWorksListItem {
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        color: $font-color-primary;
        margin: 10px 0;
      }
    }

    .howItWorksBottomContainer {
      border-top: 1px solid #60707070;
      padding-right: 40px;
      margin-top: 40px;

      .contactContainer {
        margin-top: 20px;
        display: flex;
        max-width: 420px;
        justify-content: space-between;
        background-color: white;
        padding: 12px;
        border-radius: 10px;
        color: #2c2c2c;
        font-size: 14px;

        a {
          text-decoration: none;
          color: inherit;
        }
      }
    }
  }
}
